import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Instance from '../Objects/Axios';
import alertinstance from '../Objects/Alert';
const Listgenre = ({icon}) => {
  const [list,setList]=useState([]);
let navigate = useNavigate();
useEffect(()=>{
  getGenre();
},[])


//for listing genres
function getGenre(){
  Instance.get('/listGenre')
    .then((response) => {
      //handle success
      setList([response.data])
     })
    .catch( (err) =>{
      //handle error
      console.log(err);
    });
}


//for deleting genre
function DeleteGenre(del_id){
   const params={"ids":[del_id] } 
  Instance.post('/deleteGenre',params)
    .then((response) => {
      //handle success
      alertinstance(response);
      refreshPage();
     })
    .catch((response) =>{
      //handle error
      console.log(response);
    });

}
const refreshPage = ()=>{
  setTimeout(() => {
    window.location.reload();
  }, 1000);
}

  return (
    <div className="content">
    <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
                <span className='leftpush'> Genre</span>
    </div>
   <button className='btn btn-danger mb-2'  onClick={()=>{navigate("/genre/add")}}>
     <i className="fa fa-plus"></i> Add Genre</button>

     <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Delete</th>
                        
                      </tr>
                    </thead>
                    <tbody>
                    {
                    list.map((e)=>{
                        return(
                        <>{e.data.map((q,index)=>{
                            return(
                              <>
                                <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{q.name}</td>
                                
                                <td><i className="fa fa-trash" onClick={()=>DeleteGenre(q._id)}></i></td>
                                
                                </tr>
                                </>
                            )})} 
                          </>)
                    })}
                    
                    </tbody>
      </table>             
</div>
  )
}

export default Listgenre