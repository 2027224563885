import React, { useState } from 'react'
import Select from 'react-select'
import Instance from '../Objects/Axios'
import alertinstance from '../Objects/Alert'
import {  useNavigate } from 'react-router-dom'

const Addpopularity = (icon) => {
    const [pop,setPop]=useState([])
    let navigate=useNavigate();
    const values = [
        {
            value:0,
            label:'Adults'

        },
        {
            value:1,
            label:'Suspense'

        },
        {
            value:2,
            label:'Romance'

        },
        {
            value:3,
            label:'Horror'

        },
        {
            value:4,
            label:'Sci-fi'

        },
        {
            value:5,
            label:'Drama'
    
        },
        {
            value:6,
            label:'Movies'
    
        },
        {
            value:7,
            label:'Short Films'
    
        },
        {
            value:8,
            label:'Songs'
    
        },
        {
            value:9,
            label:'Rabbit Original'
    
        },
        {
            value:10,
            label:'Hot(Audio Stories)'
    
        },
        {
            value:11,
            label:'Khat Kabadi'
    
        }
    ]
    const handlepop =(e) =>{
        setPop(Array.isArray(e)?e.map(x=>{return ({name: x.label,popularityId:x.label} ) } ):[])
    }
    const add = (e)=>{
        e.preventDefault();
        const form= {
            popularity : pop
        }
                const  data =JSON.stringify(form) 
                Instance.post('/addPopularity',data)
                .then((res)=>{
                    alertinstance(res)
                    if(res.data.error === false){
                        navigate('/popularity')
                    }
                }).catch((err)=>{
                    console.log(err);
                })

    }
  return (
    <div className="content">
       <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
                <span className='leftpush'> Add Popularity</span>
    </div>
    <form onSubmit={add}>
        <Select isMulti options={values} onChange={handlepop}>
        </Select>
        <button className='btn btn-primary mt-2' type='submit'>Add</button>
    </form>
    </div>
  )
}

export default Addpopularity