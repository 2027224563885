import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import sampler from '../Objects/axiosjson';
import alertinstance from '../Objects/Alert';
import { useNavigate } from 'react-router-dom'
import {data_type } from '../Objects/Options';
const UpdateVideoss = ({icon}) => {
 
    let {episodeName,typee}=useParams()
    let {episodeId}=useParams()
    let {contentId}=useParams()
    let {seasonId}=useParams()
    let {contentName}=useParams()
    const[side,setSide]=useState(false)
    const [osname,setosname]=useState('')
    const [client,setclient]=useState('')
    const [decoder,setdecoder]=useState('')
    const [stream,setstream]=useState('')
    const [drm,setdrm]=useState('')
    const [path,setpath]=useState('')
    const [adaptive,setadaptive]=useState('')
    const [video,setvideo]=useState(false)
    const [off,setoff]=useState(false)
    const [type,setType]=useState({label:'normal',value:'normal'})
    let {Id}=useParams()
    let navigate = useNavigate();

    useEffect(()=>{
        if(typee && typee === 'sideloaded'){
            setSide(true)
        }
        const data={
            "videoid": !episodeId ? contentId :episodeId
        }
       
        sampler.post('/listVideos',data)
        .then(res=>{
              call(res.data.data)

        }).catch(err=>{
            
            console.log(err)
        })
    },[])
   
    const call =(values)=>{
        for (var index = 0; index < values.length ; index++) {
            if (values[index]._id === Id) {
               setosname({label:values[index].platform})
               setclient({label:values[index].client})
               setdecoder({label:values[index].decoder})
               setadaptive(values[index].adaptive)
               setstream({label:values[index].streaming_type})
               setoff(values[index].offline)
               setvideo(values[index].video_exists)
               setdrm({label:values[index].drm})
               setpath(values[index].path)
               if(values[index].app_type){
                setType({label:values[index].app_type,value:values[index].app_type})
               }
            }
         }
       }

    const data_os =[
        {   value:'android',
            label:'android'
        },
        {   value:'androidTV',
            label:'AndroidTV'
        },
        {   value:'iOS-MAC',
            label:'iOS-MAC'
        },
        {   value:'appletv28',
            label:'APPLETV28'
        },
        {   value:'chromecast',
            label:'CHROMECAST'
        },
        {   value:'fIRETV28',
            label:'FIRETV28'
        }
    ]

    const data_client=[
        {
            value:'native',
            label:'native'
        },
        {
            value:'web',
            label:'web'
        },
        {
            value:'edge',
            label:'edge'
        },
    ]
     const data_decoders=[
        {   value:'avc',
            label:'avc'
        },
        {   value:'hevc',
            label:'hevc'
        },
        {   value:'vp8',
            label:'vp8'
        },
        {   value:'vp9',
            label:'vp9'
        },
        {   value:'av1',
            label:'av1'
        },
        {   value:'mpeg',
            label:'mpeg'
        },
        {
            value:'avc-high',
            label:'avc-high'
        }
        
    ]

    const data_format=[
        {
            value:'hls',
            label:'hls'
        },
        {
            value:'dash',
            label:'dash'
        },
        {
            value:'smoothstreaming',
            label:'smoothstreaming'
            
        }
        
    ]

    const data_drm=[
        {
            value:'widivine',
            label:'Widivine'
        },
        {
            value:'apple FairPlay',
            label:'Apple FairPlay'
        },
        {
            value:'playReady',
            label:'PlayReady'
        },
        {
            value:'aes-128',
            label:'AES-128'
        }
    ]
    const handleOs =(e)=>{
        setosname({label:e.label})
    }
    const handleclient = (e)=>{
        setclient({label:e.label})
    }
    const handleDecoder = (e)=>{
        setdecoder({label:e.label})
    }
    const handleFormat =(e)=>{
        setstream({label:e.label})
    }
    const handleType =(e)=>{
        setdrm({label:e.label}) 
    }
    const handleTypev =(e)=>{
        setType({label:e.value,value:e.value})
    }
    const Update =(e)=>{
        const data ={
            'id':Id,
            'platform':osname.label,
            'client':client.label,
            'decoder':decoder.label,
            'streaming_type':stream.label,
            'drm':drm.label,
            'osminimumversion':22,
            'adaptive':adaptive,
            'keytype':"static",
            'path': path,
            'video_exists':video,
            'offline':off,
            'drm_provider':"tushentertainment",
            'app_type':type.value
        }
        e.preventDefault()
        sampler.post('/updateVideos',data)
        .then(res=>{
            alertinstance(res)
            if (res.data.error === false){
                {!episodeId ?
                    setTimeout(()=>{ 
                        navigate('/videos/'+ `${contentId}`+ '/' +`${contentName}`)
                    },1000)
                : setTimeout(() => {
                    navigate('/videos/'+ `${contentId}`+'/' + `${seasonId}` + '/'+`${episodeId}` +'/' +`${episodeName}` )
                    
            }, 1000);}
            
    }
        })
        
    }
    const handleback=(e)=>{
        e.preventDefault();
        if(!side){
            navigate(`/videos/${contentId}/${seasonId}/${episodeId}/${episodeName}`)
            }else{
            navigate(`/videos/sideloaded/${contentId}/${seasonId}/${episodeId}/${episodeName}`)
            }
    }
  return (
    <div className='content'>
        <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
       <span className='leftpush'> You are adding video in {episodeName} </span>
   </div>
   <form onSubmit={Update}>
        <label><i className="fa fa-heading"></i> Select Platform</label>
        <Select options={data_os} value={osname} defaultvalue={osname} onChange={handleOs}></Select>
 
        <label><i className="fa fa-heading"></i>Supported Client</label>
        <Select options={data_client}  value={client} defaultvalue={client} onChange={handleclient}></Select>

        <label><i className="fa fa-heading"></i> Videos Decoders</label>
        <Select options={data_decoders} value={decoder} defaultValue={decoder}  onChange={handleDecoder}></Select>
        
        <label><i className="fa fa-heading"></i>Streaming Format</label>
        <Select options={data_format}  value={stream} defaultValue={stream} onChange={handleFormat}></Select>
        
        <input type="checkbox" checked={video} value={video} onChange={ e => setvideo(e.target.checked)}/>
        <label>Video Exists</label><br/>

        <input type="checkbox" checked={adaptive} value={adaptive}/>
        <label>Adaptive Streaming</label><br/>

        <input type="checkbox" checked={off} value={off} onChange={ e => setoff(e.target.checked)}/>
        <label>Available Offline</label><br/>

        <label><i className="fa fa-heading"></i>  DRM Type</label>
        <Select options={data_drm}  value={drm}  defaultValue={drm} onChange={handleType}></Select>

        <label><i className="fa fa-heading"></i>Video Type</label>
        <Select options={data_type} value={type} onChange={handleTypev}></Select> 

        <label>Video Path</label>
            <input
                name=""
                type="text" 
                value={path}
                placeholder='Enter Video Path'
                className='form-control'
                onChange={(e)=>setpath(e.target.value)}
            />
        <button type="submit" className="btn btn-primary p-2 m-2">Update</button>
        <button onClick={handleback} className="btn btn-danger p-2 m-2">Back</button>
 </form>
    </div>
  )
}

export default UpdateVideoss
