import React,{useState,useEffect} from 'react'
import MultipartAPI from '../../Objects/MultipartAPI';
import { useNavigate } from 'react-router-dom';
import '../../css/content.css';
const Listsubs = () => {
  const [listnew,setlistnew]=useState({mobile:[],large:[],tv:[]});
  let navigate = useNavigate();
  useEffect(()=>{
    MultipartAPI.get('/getPackages')
    .then(res=>{
        console.log(res)
        setlistnew(res.data.data)
      }).catch(err=>{
        console.log(err)
      })
},[])
const [hover,sethover]=useState(false)
const [packageId,setPackageId]=useState('')
const [platform,setPlatform]=useState('')
const checkcontent=(packageId,device)=>{
      sethover(!hover)
      setPackageId(packageId)
      setPlatform(device)
}
const deletePackage=(packageId)=>{
  const params = {
    "packageId":packageId,
    "platform":platform
  }
  console.log(params)
  /*MultipartAPI.post('/deletePackages',params)
  .then(res=>{
      console.log(res)
    }).catch(err=>{
      console.log(err)
    })*/
}
  return (
    <div className='content'>
    <div className={hover ? 'list shadow':'listnone shadow'}>
                <li onClick={()=>{navigate("update/"+`${packageId}`)}}><i className='fa fa-eye'/>Update Package</li>
                <li onClick={()=>{deletePackage(packageId)}}><i className='fa fa-trash'/>Delete Package</li>
    </div>
    <div id="exTab2" className="container">	
            <ul className="nav nav-tabs">
                <li className="active"><a  href="#1" data-toggle="tab">Mobile</a></li>
                <li><a href="#2" data-toggle="tab">Large Screen</a></li>
                <li><a href="#3" data-toggle="tab">TV</a></li>
            </ul>
                <div className="tab-content ">
                <div className="tab-pane active" id="1">
                <button className='btn btn-primary'  onClick={()=>{navigate("add/mobile")}}><i className="fa fa-plus"></i> Add Package</button>
                  <table className="table table-striped mt-2">
                  <thead>
                      <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Period</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Platfrom</th>
                      <th scope="col">Benefits</th>
                      <th scope="col">Options</th>
                      </tr>
                  </thead>
                  <tbody>
                    {listnew.mobile.map((q,index)=>{
                              return(
                                              <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{q.name}</td>
                                                  <td>{q.period}</td>
                                                  <td>{q.price.price}</td>
                                                  <td>{q.status}</td>
                                                  <td>{q.platform}</td>
                                                  <td>{q.benefits.map((x,index)=> {return  <li key={index}> {x} </li>})}</td>
                                                  <td><i className="fa fa-ellipsis-h ms-4" onClick={() => checkcontent(q._id,"mobile")}></i>
                                                </td> 
                                              </tr>
                                          )
                                          
                    })}
                  </tbody>
                  </table>
                </div>
                <div className="tab-pane" id="2">
                <button className='btn btn-primary'  onClick={()=>{navigate("add")}}><i className="fa fa-plus"></i> Add Package</button>
                <table className="table table-striped mt-2">
                  <thead>
                      <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Period</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Platfrom</th>
                      <th scope="col">Benefits</th>
                      <th scope="col">Options</th>
                      </tr>
                  </thead>
                  <tbody>
                    {listnew.large.map((q,index)=>{
                              return(
                                              <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{q.name}</td>
                                                  <td>{q.period}</td>
                                                  <td>{q.price.price}</td>
                                                  <td>{q.status}</td>
                                                  <td>{q.platform}</td>
                                                  <td>{q.benefits.map((x,index)=> {return  <li key={index}> {x} </li>})}</td>
                                                  <td><i className="fa fa-ellipsis-h ms-4" onClick={() => checkcontent(q._id,"large")}></i>
                                                </td> 
                                              </tr>
                                          )
                                          
                    })}
                  </tbody>
                </table>
                </div>
                <div className="tab-pane" id="3">
                <button className='btn btn-primary'  onClick={()=>{navigate("add")}}><i className="fa fa-plus"></i> Add Package</button>
                <table className="table table-striped mt-2">
                  <thead>
                      <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Name</th>
                      <th scope="col">Period</th>
                      <th scope="col">Price</th>
                      <th scope="col">Status</th>
                      <th scope="col">Platfrom</th>
                      <th scope="col">Benefits</th>
                      <th scope="col">Options</th>
                      </tr>
                  </thead>
                  <tbody>
                    {listnew.tv.map((q,index)=>{
                              return(
                                              <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{q.name}</td>
                                                  <td>{q.period}</td>
                                                  <td>{q.price.price}</td>
                                                  <td>{q.status}</td>
                                                  <td>{q.platform}</td>
                                                  <td>{q.benefits.map((x,index)=> {return  <li key={index}> {x} </li>})}</td>
                                                  <td><i className="fa fa-ellipsis-h ms-4" onClick={() => checkcontent(q._id,"tv")}></i>
                                                </td> 
                                              </tr>
                                          )
                                          
                    })}
                  </tbody>
                </table>
                </div>
                </div>
    </div>
    </div>
  )
}

export default Listsubs