import React,{useState,useEffect} from 'react'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import sampler from '../Objects/axiosjson';
import alertinstance from '../Objects/Alert';
import { useNavigate } from 'react-router-dom'
import { data_type } from '../Objects/Options';
const Videos = ({icon}) => {
    let {episodeName,typee}=useParams()
    let {episodeId}=useParams()
    let {contentId}=useParams()
    let {seasonId}=useParams()
    let {contentName}=useParams()
    const [osname,setosname]=useState('')
    const [client,setclient]=useState('')
    const [decoder,setdecoder]=useState('')
    const [stream,setstream]=useState('')
    const [drm,setdrm]=useState('')
    const [id,setid]=useState('')
    const [path,setpath]=useState('')
    const [streaming,setstreaming]=useState(true)
    const [video,setvideo]=useState(false)
    const [off,setoff]=useState(false)
    const [type,setType]=useState('normal')
    const[side,setSide]=useState(false)
    let navigate = useNavigate();

    useEffect(()=>{
        if(typee && typee === 'sideloaded'){
            setSide(true)
        }
        if(!episodeId){
            setid(contentId)
        }
        else{
            setid(episodeId)}
    })
  

    const data_os =[
        {   value:'android',
            label:'android'
        },
        {   value:'androidTV',
            label:'AndroidTV'
        },
        {   value:'iOS-MAC',
            label:'iOS-MAC'
        },
        {   value:'appletv28',
            label:'APPLETV28'
        },
        {   value:'chromecast',
            label:'CHROMECAST'
        },
        {   value:'fIRETV28',
            label:'FIRETV28'
        }
    ]

    const data_client=[
        {
            value:'native',
            label:'native'
        },
        {
            value:'web',
            label:'web'
        },
        {
            value:'edge',
            label:'edge'
        },
    ]
   

    const data_decoders=[
        {   value:'avc',
            label:'avc'
        },
        {   value:'hevc',
            label:'hevc'
        },
        {   value:'vp8',
            label:'vp8'
        },
        {   value:'vp9',
            label:'vp9'
        },
        {   value:'av1',
            label:'av1'
        },
        {   value:'mpeg',
            label:'mpeg'
        },
        {
            value:'avc-high',
            label:'avc-high'
        }
        
    ]

    const data_format=[
        {
            value:'hls',
            label:'hls'
        },
        {
            value:'dash',
            label:'dash'
        },
        {
            value:'smoothstreaming',
            label:'smoothstreaming'
            
        }
        
    ]

    const data_drm=[
        {
            value:'widivine',
            label:'Widivine'
        },
        {
            value:'apple FairPlay',
            label:'Apple FairPlay'
        },
        {
            value:'playReady',
            label:'PlayReady'
        },
        {
            value:'aes-128',
            label:'AES-128'
        }
    ]
    const handleOs =(e)=>{
        setosname(e.label)
    }
    const handleclient = (e)=>{
        setclient(e.label)
    }
    const handleDecoder = (e)=>{
        setdecoder(e.label)
    }
    const handleFormat =(e)=>{
        setstream(e.label)
    }
    const handleType =(e)=>{
        setdrm(e.label)
    }

    const handleTypev =(e)=>{
        setType(e.value)
    }

    const data ={
        'videoid':id,
        'platform':osname,
        'client':client,
        'decoder':decoder,
        'streaming_type':stream,
        'drm':drm,
        'osminimumversion':22,
        'adaptive':streaming,
        'keytype':"static",
        'path':path,
        'video_exists':video,
        'offline':off,
        'drm_provider':"tushentertainment",
        'app_type':type
    }
    
    const Add =(e)=>{
        e.preventDefault()
        sampler.post('/addVideos',data)
        .then(res=>{
            alertinstance(res)
            if (res.data.error === false){
            {!episodeId ?
                setTimeout(()=>{ 
                    navigate('/videos/'+ `${contentId}`+ '/' +`${contentName}`)
                },1000)
            : setTimeout(() => {
                navigate('/videos/'+ `${contentId}`+'/' + `${seasonId}` + '/'+`${episodeId}` +'/' +`${episodeName}` )
                
        }, 1000);
        }}
        })
    }
    const handleback=(e)=>{
        e.preventDefault();
        if(!side){
        navigate(`/videos/${contentId}/${seasonId}/${episodeId}/${episodeName}`)
        }else{
        navigate(`/videos/sideloaded/${contentId}/${seasonId}/${episodeId}/${episodeName}`)
        }
    }
  return (
    <div className='content'>
        <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
       <span className='leftpush'> You are adding video in {episodeName} </span>
   </div>
   <form onSubmit={Add}>
        <label><i className="fa fa-heading"></i> Select Platform</label>
        <Select options={data_os} onChange={handleOs}></Select>

        <label><i className="fa fa-heading"></i>Supported Client</label>
        <Select options={data_client} onChange={handleclient}></Select>

        <label><i className="fa fa-heading"></i> Videos Decoders</label>
        <Select options={data_decoders} onChange={handleDecoder}></Select>
        
        <label><i className="fa fa-heading"></i>Streaming Format</label>
        <Select options={data_format} onChange={handleFormat}></Select>
        
        <input type="checkbox" checked={streaming} />
        <label> Adaptive Streaming</label><br/>

        <input type="checkbox" checked={video} onChange={ e => setvideo(e.target.checked)}/>
        <label>Video Exists</label><br/>

        <input type="checkbox" checked={off} onChange={ e => setoff(e.target.checked)}/>
        <label>Available Offline</label><br/>

        <label><i className="fa fa-heading"></i>DRM Type</label>
        <Select options={data_drm} onChange={handleType}></Select>

        {/* <label><i className="fa fa-heading"></i>Video Type</label>
        <Select options={data_type} onChange={handleTypev}></Select> */}

        <label>Video Path</label>
            <input
                name=""
                type="text" 
                placeholder='Enter Video Path'
                className='form-control'
                onChange={(e)=>setpath(e.target.value)} 
            />
        <button type="submit" className="btn btn-primary p-2 m-2">submit</button>
        <button onClick={handleback} className="btn btn-danger p-2 m-2">Back</button>
 </form>
    </div>
  )
}

export default Videos
