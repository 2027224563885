import React,{useState,useEffect} from 'react'
import {
    useNavigate,
  } from "react-router-dom";
import alertinstance from '../../Objects/Alert';
import sampler from '../../Objects/axiosjson';
const Listmarketing = ({icon}) => {
    const [list,setlist]=useState([])
    const [addId,setaddId]=useState('')
    const [addTitle,setaddTitle]=useState('')
    const [hover,sethover]=useState(false)
    let navigate = useNavigate();
    useEffect(()=>{
        const fetch=async()=>{
            sampler.get('/listPosterAdvertisement')
            .then(res=>{
                if(res.data.error === false){
                    setlist(res.data.data)
                }
            }).catch(err=>{
                console.log(err)
            })
        }
        fetch()
    },[])
    const deleteadvertise=()=>{
        const data ={
            posterId:addId
        }
        sampler.post('/deletePosterAdvertisement',data)
        .then(res=>{
            alertinstance(res)
            if(!res.data.error){
                window.location.reload()
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    const toggleDropdown=(id,name)=>{
        sethover(!hover)
        setaddId(id)
        setaddTitle(name)
    }
    const formate =(epoch)=>{
        const date = new Date(epoch).toDateString()
        return(
            <td>{date}</td>
        )
    }
  return (
    <div className='content'>
        <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
                <span className='leftpush'>All Advertisements </span>
        </div>
        <button className='btn btn-danger m-2'  onClick={()=>{navigate('/marketing/add')}}><i className="fa fa-plus"></i> Add Poster</button>
        <div className={hover ? 'list shadow':'listnone shadow'}>
            <li onClick={()=>navigate(`/marketing/update/${addId}/${addTitle}`)}><i className="fa fa-edit"></i> Edit Advertisement</li>   
            <li onClick={()=>deleteadvertise()}><i className="fa fa-trash"></i> Delete Advertisement</li>
        </div>
        <table class="table">
            <thead>
                <tr>
                <th scope="col">No</th>
                <th scope="col">Thumbnail</th>
                <th scope="col">Name</th>
                <th scope="col">Title</th>
                <th scope="col">Description</th>
                <th scope="col">Expiry Date</th>
                </tr>
            </thead>
            <tbody>
            {list.map( (x,index) =>{
            return(
                <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td><img src={`https://d148xld5ybft60.cloudfront.net/${x.landscapePosterAdd}`} style={{'width':'220px','height':'150px'}}/></td>
                <td>{x.name}</td>
                <td>{x.title}</td>
                <td>{x.description}</td>
                {formate(x.expirydate)}
                <td><i className="fa fa-ellipsis-h" onClick={() => toggleDropdown(x._id,x.name)}></i></td>
                </tr>
            )
            })}
            </tbody>
        </table>
        </div>
  )
}

export default Listmarketing