import React,{useState,useEffect} from 'react'
import MultipartAPI from '../../Objects/MultipartAPI';
import { useNavigate, useParams } from 'react-router-dom';
import '../../css/content.css';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
const Addsubs = () => {
  let {platform} = useParams()
  let navigate = useNavigate();
  const [query, setQuery] = useState("")
  const [searches, setSearches] = useState([])
  const [data,setdata]=useState({name:'',status:false,period:'',price:{currency:'',value:''},date:''})
  useEffect(()=>{
    console.log(platform)
  },[])
  const addPackage=(e)=>{
    e.preventDefault()
    data["benefits"] = searches;
    console.log(data)

  }
  const updateQuery = ({ target }) => {
    // Update query onKeyPress of input box
    setQuery(target.value)
  }

  const addActor = () => {
    if(query !== ''){
      setSearches(searches => [...searches, query])
      setQuery('');
      console.log(searches,query)
    }else{
      console.log('Field is mandatory')
    }
    // Prevent form submission on Enter key
    
  }
  const deleteRow = (index) => {
    //let name="Mano"
    //setEmps(emps.filter(emp => emp.name !== name))
    let copy_emp=[...searches]
    copy_emp.splice(index,1)
    setSearches(copy_emp)
}

  return (
    <div className="content">
    <form onSubmit={addPackage}>
            <label><i className="fa fa-heading"></i> Name</label>
                <input
                    className="form-control"
                    placeholder="Enter name of the package"
                    type="text"
                    onChange={(e)=>setdata({...data, name:e.target.value})}
                />
           
            <label><i className="fa fa-calendar-alt"></i> Date</label>
                <input
                    className="form-control"
                    type="datetime-local"
                    onChange={(e)=>setdata({...data, date:e.target.value})}
                />

            <input type="checkbox"  checked={data.status} onChange={e => setdata({...data,status:e.target.checked})}/>
            <label> Status </label><br/>
            
            <label><i className="fa fa-user"></i> Price</label>
                <input
                    className="form-control"
                    placeholder="Enter price (INR)"
                    type="text"
                    onChange={(e)=>setdata({...data,price:{currency:'INR',value:e.target.value}})}
                />
             <label><i className="fa fa-film"></i> Period</label><br/>
                <input
                    className="form-control"
                    placeholder="Enter period"
                    type="text"
                    onChange={(e)=>setdata({...data,period:e.target.value})}
                />            
            
            <label><i className="fa fa-user"></i> Add Benefits</label>
                <input
                    value={query}
                    className="form-control"
                    placeholder="Enter benefits*"
                    type="text"
                    onChange={updateQuery}
                />
                <button type='button' className="btn btn-primary mt-2" onClick={()=>addActor()}> Add Benefit </button>
            <Stack direction="row" spacing={1} className="stack">
                    {searches.map((x,index) =>{
                        return(
                            <>
                            <Chip key={index} label={x} style={{'fontSize':'16px'}}onDelete={()=>deleteRow(index)}/>
                            </>
                        )
                    })}
            </Stack>

            <div className='flex-back-button'>
            <button type="submit" className='btn btn-primary'> Add</button>
            <button className="btn btn-danger" onClick={()=>{navigate("/subscriptions")}}>Back</button>
            </div>
    </form>
</div>
  )
}

export default Addsubs