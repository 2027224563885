import Swal from "sweetalert2";
const Userinput = (x) => {
    if(x.reelId !== '') {
        return Swal.fire({
            title: 'Do you really want to delete this reel?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Back`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              //Swal.fire('Deleted!', '', 'success')
              return true
            } else if (result.isDenied) {
              Swal.fire('not Deleted', '', 'info')
              return false
            }
          })
    }
    else{
        Swal.fire({
            title: 'error',
            text: x.data.message,
            icon: 'error',
            })
    }
}   
export default Userinput; 