import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import Instance from '../Objects/Axios'
import alertinstance from '../Objects/Alert'


const Listpopularity = ({icon}) => {
  const[popular,setPopular]=useState([])
  let navigate=useNavigate();
  useEffect(()=>{
    getPop();
  },[])
  function getPop(){
    Instance.get('/listPopularity')
      .then((response) => {
        //handle success
        setPopular([response.data])
        
       })
      .catch( (err) =>{
        //handle error
        console.log(err);
      });
  }
  function DeletePop(pop_id){
     const params={"ids":[pop_id] } 
    Instance.post('/deletePopularity',params)
      .then((response) => {
        //handle success
        alertinstance(response);
        refreshPage();
        
       })
      .catch((response) =>{
        //handle error
        console.log(response);
      });
  
  }
  const refreshPage = ()=>{
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  return (
    <div class ="content">
      <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon} ></i>
                <span className='leftpush'> Popularity</span>
              </div>
        <button className='btn btn-danger mb-2' onClick={()=>{navigate("/popularity/add")}}>
            <i className="fa fa-plus" ></i> Add popularity</button>
            <table className="table table-striped">
                    <thead>
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Name</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                    popular.map((e)=>{
                        return(
                        <>{e.data.map((p,index)=>{
                            return(
                              <>
                                <tr>
                                <th scope="row">{index + 1}</th>
                                <td>{p.name}</td>
                                
                                <td><i className="fa fa-trash" onClick={()=>DeletePop(p._id)}></i></td>
                                
                                </tr>
                                </>
                            )})} 
                          </>)
                    })}
                    
                    </tbody>
      </table>  
    </div>
    
  )
}

export default Listpopularity