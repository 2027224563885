import React,{useState} from 'react'
import Select from 'react-select'
import Instance from '../Objects/Axios'
import alertinstance from '../Objects/Alert'
import { useNavigate } from 'react-router-dom'

const Addgenre = ({icon}) => {
    const [genre,setgenre]=useState([])
    let navigate = useNavigate()
const  values=[
    {
        value:0,
        label:'Romance'
    },
    {
        value:1,
        label:'Action'
    },
    {
        value:2,
        label:'Suspense'
    },
    {
        value:3,
        label:'Adults'
    },
    {
        value:4,
        label:'Horror'
    },
    {
        value:5,
        label:'Science Fiction'
    },
    {
        value:6,
        label:'Comedy'
    },
    {
        value:7,
        label:'Thriller'
    }

    
]
const handlegenre =(e)=>{
    setgenre(Array.isArray(e)?e.map(x=>{return ({name: x.label,genreId:x.label.toLowerCase()} ) } ):[])
}
const add =(e)=>{
    e.preventDefault();
    const form= {
        genre : genre
    }
    const  data =JSON.stringify(form) 
            Instance.post('/addGenre',data)
            .then((res)=>{
                alertinstance(res)
                if(res.data.error==false){
                    navigate('/genre')
                }
            }).catch((err)=>{
                console.log(err);
            })
}

  return (
    <div className="content">
    <div class="alert alert-primary">  <i class="fa fa-bars" onClick={icon}></i>
                <span className='leftpush'> Add Genre</span>
    </div>
     <form onSubmit={add}>
       <Select isMulti options={values} onChange={handlegenre}></Select>
      
     <button className='btn btn-primary mt-2' type='submit'>Add</button>
    </form> 
    </div>
  )
}

export default Addgenre