import React,{useState,useEffect} from 'react'
import '../../css/content.css';
import sampler from '../../Objects/axiosjson';
import {
    useNavigate,
    
  } from "react-router-dom";
import '../../css/upcomming.css';
import '../../css/content.css';
import alertinstance from '../../Objects/Alert';
import Refresh from '../../Objects/Refresh';
import Userinput from '../../Objects/Userinput';

const ListReels = ({icon}) => {
  let navigate = useNavigate();
  const [listnew,setlistnew]=useState([]);
  useEffect(()=>{
    sampler.get('/listReels?app_type=normal')
    .then(res=>{
      if(res.data.data){
        setlistnew(res.data.data)
      }
      }).catch(err=>{
        console.log(err)
      })
   },[])
   const [hover,sethover]=useState(false);
   const [reelId,setReelId]=useState('')
   const checkcontent=(id)=>{
    setReelId(id)
    sethover(!hover)
   }
   const transformdate=(e)=>{
    const date = new Date(e).toDateString();
    return `${date}`
   }
   const deleteReel=async ()=>{
    const params ={
      reelId:reelId
    }
    const flag = await Userinput(params)
    if(flag){
      sampler.post('/deleteReel',params)
      .then(res=>{
        alertinstance(res)
        if(res.data.error === false){
          Refresh()
        }
      }).catch(err=>{
        console.log(err)
      })
    }
   }
  return (
    <div className="content">
        <div className="alert alert-primary">  <i className="fa fa-bars" onClick={icon}></i>
                <span className='leftpush'>Reels</span>
        </div>
            <button className='btn btn-danger'  onClick={()=>{navigate("/reels/add")}}><i className="fa fa-plus"></i> Add Reel</button>

            {/*for listing content  */}
            <div className={hover ? 'list shadow':'listnone shadow'}>
                <li onClick={()=>{navigate(`/reels/update/${reelId}`)}}>
                  <i className='fa fa-edit'/> Edit Reel
                </li>
                <li onClick={()=>deleteReel()}>
                <i className='fa fa-trash'/> Delete Reel
                </li>
            </div>

            <table className="table table-striped mt-2">
                <thead>
                    <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Thumbnail</th>
                    <th scope="col">Title</th>
                    <th scope="col">Published</th>
                    <th scope="col">NumLikes</th>
                    <th scope="col">NumViews</th>
                    <th scope="col">Auto Expiry</th>
                    </tr>
                </thead>
                <tbody>
            {listnew.map((q,index)=>{
                            return(
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td><img src={ 'https://d2wjage1kevvk2.cloudfront.net/'+q.reelThumbnailId}/></td>
                                                <td>{q.title ? q.title:''}</td>
                                                <td>{q.published ? 'Published':'Unpublished'}</td>
                                                <td>{q.numLikes === null ? 0:q.numLikes}</td>
                                                <td>{q.numViews === null ? 0:q.numViews}</td>
                                                <td>{ q.autoExpiry ? 'enabled':'disabled'}</td>
                                                <td>
                                                <i className="fa fa-ellipsis-h ms-4" 
                                                onClick={() => checkcontent(q._id)}></i>
                                               </td> 
                                            </tr>
                                  )
                                        
                            })}
                </tbody>
            </table>
        
        </div>
  )
}

export default ListReels